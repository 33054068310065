import React from 'react';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@material-ui/core/TextField';
import Icon from "@material-ui/core/Icon";
import CalendarSvg from '../../assets/icons/calendar-stroke.svg';
import dayjs from 'dayjs';

const materialTheme = createTheme({
  components: {
    MuiPickersToolbar: {
      styleOverrides: {
        toolbar: {
          backgroundColor: '#157478',
        },
      },
    },
    MuiPickersCalendarHeader: {
      styleOverrides: {
        switchHeader: {
          backgroundColor: 'white',
          color: '#157478',
          padding: '5px'
        }
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          padding: 0
        }
      }
    }
  }
});

const SelectDate = ({ value, onChange, format, style, minDate, maxDate, views, disabled, onError }) => {  
  const calendarIcon = (<Icon><img alt="" src={CalendarSvg} style={{width: "90%"}}/></Icon>);

  const url = window.location.pathname;
  const parts = url.split('/');
  const lastPart = parts[parts.length - 1];
  
  console.log(lastPart, "lastPart");

  // Ensure value is a dayjs object
  const dateValue = value ? dayjs(value) : null;
  
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <ThemeProvider theme={materialTheme}>
        <DatePicker
          value={dateValue}
          onChange={(newValue) => onChange(newValue ? newValue.toDate() : null)}
          format={format || 'YYYY-MM'}
          inputFormat={format || 'YYYY-MM'}
          renderInput={(params) => (
            <TextField
              {...params}
              InputProps={{
                disableUnderline: true,
                style: {
                  color: '#00000',
                  backgroundColor: '#D9DBE9',
                  borderRadius: '4px',
                  outline: 'none',
                  padding: 6,
                  ...style
                }
              }}
              disabled={disabled}
            />
          )}
          minDate={lastPart === "tenderManagement" ? dayjs(minDate) : dayjs(minDate) || dayjs()}
          maxDate={lastPart === "tenderManagement" ? dayjs(maxDate) : dayjs(maxDate) || dayjs(8640000000000000)} // If empty, get maximum date value
          components={{
            OpenPickerIcon: calendarIcon
          }}
          views={views || ['year', 'month']} // Show only year and month
          onError={onError}
        />
      </ThemeProvider>
    </LocalizationProvider>
  )
}

export default SelectDate;