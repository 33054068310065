import { avrSurveyActionTypes } from "../constants/actionTypes"

const initialState = {
	principals: [],
	products: [],
	doubleValue: [],
	totalProduct: []
}

export const avrSurveyReducer = (state = initialState, { type, payload = {} }) => {
	switch (type) {
		case avrSurveyActionTypes.MASTER_DATA_LOAD: {
			return {
				...state,
				...Object.fromEntries(
					Object.entries(payload.data.data).map(items => {
						return [
							items[0],
							items[1].map(v => ({ value: v.key, label: v.value }))
						]
					})
				)
			}
		}
		case avrSurveyActionTypes.PRINCIPAL_DATA_LOAD: {
			const principal = payload.data.data
				.filter(item => item.shipToCode.includes(payload.outletCode))
				.map(item => item.product.map((item) => ({ label: item?.principalName, value: item?.principalCode })));

			const flattenedPrincipal = principal.flat(); // flat() is using for remove 1 extra layer of array.

			const product = payload.data.data
				.filter(item => item.shipToCode.includes(payload.outletCode))
				.map(item => item.product.map((item) => ({ label: item?.productName, value: item?.productId })));

			const flattenedProduct = product.flat(); // flat() is using for remove 1 extra layer of array.

			const doubleValue = payload.data.data
				.filter(item => item.shipToCode.includes(payload.outletCode))
				.map(item => item.product.map((product) => ({ key: product.productId, value: product?.inputedQty })));

			const flattenedDoubleValue = doubleValue.flat(); // flat() is using for remove 1 extra layer of array.

			const allProducts = payload.data.data
				.filter(item => item.shipToCode.includes(payload.outletCode))


			// console.log(allProducts, "allProducts");

			return {
				...state,
				principals: flattenedPrincipal,
				products: flattenedProduct,
				doubleValue: flattenedDoubleValue,
				totalProduct: allProducts[0]?.product
			}
		}
		case avrSurveyActionTypes.PRODUCT_DATA_LOAD: {
			// console.log("payload--product", payload.data.data);
			return {
				...state,
				products: payload.data.data
					.map(({ product }) => ({ label: product.productName, value: product.productId })),
				// doubleValue: payload.data.data
				// 	.map(({ inputedQty, product }) => ({ key: product.productId, value: inputedQty }))
			}
		}

		// changes by Hardik start

		case "CHANGE_PRINCIPAL": {
			const product = state?.totalProduct?.filter((item) => item?.principalCode === payload);
			const updatedProductList = product.map((item) => ({ label: item?.productName, value: item?.productId }))

			return {
				...state,
				products: updatedProductList,
			}
		}

		// changes by Hardik close

		default: return state
	}
}