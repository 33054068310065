import { format, parseISO } from 'date-fns'
import { EngageConstant } from '../constant';
import Table from './useTable'
import { bufferResponseInstance } from './http-common';


const REGEX = {
    hhMM: /^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/
}

const formatDate = (data, formatDate) => data && format(parseISO(data), formatDate);

const toCurrency = (rupiah) => new Intl.NumberFormat('ID').format(Number(rupiah));

const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1);

const stringEllipsis = (str, length) => (str.length > length) ? `${str.substring(0, length - 1)}...` : str;

const parseJwt = (token) => {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
};

const getDropDownOption = (value, inputValue) => {
    return value === EngageConstant.OTHERS ? inputValue : value;
};

const setDropDownOption = (value, options) => {
    return value
        ? [...options].find((e) => e.value === value) || { value: EngageConstant.OTHERS, label: EngageConstant.OTHERS }
        : '';
};

const setOption = (options, value) => {
    return value && options && [...options].find(c => c.value === value);
}

const validHhMM = (v) => REGEX.hhMM.test(v);

const setInputOption = (value, options) => options.some(e => e.value === value) ? '' : value;

const getLocalStore = (key) => sessionStorage.getItem(key);

const setLocalStore = (key, value) => sessionStorage.setItem(key, value);

function isNumeric(str) {
    if (typeof str != "string") return false // we only process strings!  
    return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
        !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
}

const equalTime = (date1, date2) => {
    const d1 = new Date(date1).getTime();
    const d2 = new Date(date2).getTime();

    return d1 === d2;
};

const equalDate = (date1, date2) => {
    const d1 = new Date(date1.getFullYear(), date1.getMonth(), date1.getDate()).getTime();
    const d2 = new Date(date2.getFullYear(), date2.getMonth(), date2.getDate()).getTime();

    return d1 === d2;
};

const getDaysInMonthUTC = (month, year) => {
    var d = new Date(Date.UTC(year, month, 1));
    var days = [];
    while (d.getUTCMonth() === month) {
        days.push(new Date(d));
        d.setUTCDate(d.getUTCDate() + 1);
    }

    return days;
}

async function downloadExcelFileFromBlob (url, params, fileName)  {
    await bufferResponseInstance.get(url, { params })
    .then(res => {
            if (res.status !== 200) return;
            const href = URL.createObjectURL(res.data);

            // create "a" HTML element with href to file & click
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', fileName); //or any other extension
            document.body.appendChild(link);
            link.click();

            // clean up "a" element & remove ObjectURL
            document.body.removeChild(link);
            URL.revokeObjectURL(href);

        }).catch(err => {
            alert("No Record Found!!");
        })
}

const getParameterMstCodeValue = (parameterMst, para_code, team_id='', salesorg='' ) => {
    // return parameterMst.find((e) => e.parameterCode === team_id)?.parameterValue;
    let curr_paraMst = {};
    if(team_id){
        curr_paraMst =  parameterMst.find((e) => (e.parameterteamid === team_id && e.parametercode === para_code));
        // console.log(`curr_paraMst::team_id=${team_id}::para_code=${para_code}`, curr_paraMst);
    }
    if(salesorg && !curr_paraMst){
        curr_paraMst =  parameterMst.find((e) => (e.salesorg === salesorg && e.parametercode === para_code));
        // console.log(`curr_paraMst::salesorg=${salesorg}::para_code=${para_code}`, curr_paraMst);
    }
    if(!curr_paraMst){
        curr_paraMst =  parameterMst.find((e) => (e.parametercode === para_code));
        // console.log(`curr_paraMst-parametercode-${para_code}`, curr_paraMst);
    }
    // console.log('parameterMst', parameterMst);
    // console.log('para_code', para_code);
    // console.log('team_id', team_id);
    // console.log('salesorg', salesorg);

    if(curr_paraMst?.teamcode && curr_paraMst?.teamvalue !== 'N'){
        // the condition is because we can have teamvalue as int too, so we need to check if it is 'N' and N means lets NO
        console.log(`returning teamvalue=${curr_paraMst?.teamvalue} for para_code=${para_code}`);
        return { 
            "code": curr_paraMst?.teamcode, 
            "value": curr_paraMst?.teamvalue,
            "teamvalue": curr_paraMst?.teamvalue,
            "paramtervalue": curr_paraMst?.parametervalue
        };
    }
    else if(curr_paraMst?.parametercode && curr_paraMst?.parametervalue){
        console.log(`returning parametervalue=${curr_paraMst?.parametervalue} for para_code=${para_code}`);
        return { 
            "code": curr_paraMst?.parametercode, 
            "value": curr_paraMst?.parametervalue,
            "teamvalue": curr_paraMst?.teamvalue,
            "paramtervalue": curr_paraMst?.parametervalue
        };
    }
    else{
        return { 
            "code": '', 
            "value": '',
            "teamvalue": curr_paraMst?.teamvalue,
            "paramtervalue": curr_paraMst?.parametervalue
        };
    }

}



export {
    Table,
    getLocalStore,
    setLocalStore,
    parseJwt,
    equalDate,
    equalTime,
    validHhMM,
    isNumeric,
    setOption,
    formatDate,
    toCurrency,
    setInputOption,
    stringEllipsis,
    getDropDownOption,
    setDropDownOption,
    getDaysInMonthUTC,
    capitalizeFirstLetter,
    downloadExcelFileFromBlob,
    getParameterMstCodeValue
}
