import React from 'react'
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1)
    }
  },
  input: {
    display: 'none'
  },
  buttonUpload: {
    // margin: theme.spacing(1),
    width: '100%',
    borderRadius: 50,
    outline: 'border none',
    backgroundColor: 'rgba(206, 220, 39, 1)',
    textAlign: 'center',
    color: 'white',
    '&:hover': {
      backgroundColor: 'rgba(206, 220, 39, 1)',
      color: 'white'
    },
    '&:focus': {
      outline: 'none'
    }
  }
}))

const ButtonUpload = ({ color, type, fullWidth, className, onClick, disabled, children }) => {
  const classes = useStyles()
  return (
    <div>
      <input
        accept='image/*'
        className={classes.input}
        id='contained-button-file'
        multiple
        type='file'
      />
      <Button
        variant='contained'
        color='default'
        className={classes.buttonUpload}
        component='span'
      >
        {children}
      </Button>
    </div>
  )
}

export default ButtonUpload
