import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import { Box } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        display: 'flex',
        padding: '2px 4px',
        alignItems: 'center',
        background: 'rgba(239, 240, 247, 1)',
        borderRadius: 50,
        boxShadow: 'none',
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    iconButton: {
        padding: 5,
    },
}));

const BaseSearch = (props) => {
    const {
        id,
        name,
        type,
        value,
        onChange,
        isDisabled,
        placeholder,
        onClick,
        className,
        onKeyDown,
        autoComplete
    } = props;
    const classes = useStyles();

    return (
        <Box className={classes.root}>
            <InputBase
                id={id}
                name={name}
                type={type}
                value={value}
                onChange={onChange}
                disabled={isDisabled}
                className={className}
                classes={{
                    root: classes.input,
                }}
                placeholder={placeholder || "Search"}
                autoComplete={autoComplete}
                onKeyDown={onKeyDown}
            />
            <IconButton onClick={onClick} className={classes.iconButton} aria-label="search">
                <SearchIcon />
            </IconButton>
        </Box>
    );
}

export default React.memo(BaseSearch);