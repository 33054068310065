import React from 'react'
import ReactDOM from 'react-dom/client'
import './styles/tailwind.css'
import './i18n'
import App from './App'
import { PersistGate } from 'redux-persist/integration/react'
import { Provider } from 'react-redux'
import { store, persistor } from './redux/store'
import 'leaflet/dist/leaflet.css'
import {
  BrowserRouter as Router,
  Routes,
  Route
} from 'react-router-dom'
import { ToastContainer, Slide } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { injectStore } from './utils/http-common'
import { injectOldStore } from './utils/request'
import { ThemeProvider, createTheme } from '@mui/material/styles';

injectStore(store);
injectOldStore(store);

const container = document.getElementById('root');
const root = ReactDOM.createRoot(container);
const theme = createTheme({
  spacing: 8, // Define the spacing method
  // Add other theme configurations if needed
});

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      {/* <Router>
        <Routes>
          <Route path="/*" element={<App />} />
        </Routes> */}
        <ThemeProvider theme={theme}>
          <Router>
              <App />
          </Router>
          <ToastContainer
            position="top-right"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            transition={Slide}
          />
        </ThemeProvider>
      {/* </Router> */}
    </PersistGate>
  </Provider>
  // ,
  // document.getElementById('root')
)
