import React from 'react'
import { Link } from 'react-router-dom'
import { headerStyle, style } from './styles'
import { toCurrency, formatDate } from './common'
import './styles/style.css'
import ProductDetailPopup from '../components/product/ProductDetailPopup'
import deleteIcon from '../assets/icons/delete.svg'
import locationIcon from '../assets/icons/location.png'
import editIcon from '../assets/icons/edit.png'
import PopupDistance from '../components/report/visitcompliancereport/PopupDistance';
import PopupDetailTracking from '../components/salesmanactivity/salesmantrackingreport/PopupDetailTracking';
import MenuItem from '@mui/material/MenuItem';
import SelectOption from '../components/select/SelectOption'
import PromoByProductList from '../components/product/PromoByProductList';

const useFieldRowThree = (props) => {
    const {
        checkboxProps,
        selectedData,
        handleSelectedAllRows,
        handleSelectRow,
        handleDetailTarget,
        handleEditTarget,
        handleEditDetailTarget,
        detail,
    } = props

    return {
        productList: [
            {
                Header: 'Product code',
                accessor: 'productGroupCode',
                headerStyle,
                style,
                sortable: false,
            },
            {
                Header: 'Product Name',
                accessor: 'productName',
                headerStyle,
                style,
                sortable: false,
                Cell: ({ original }) => {
                    return <ProductDetailPopup data={original} />
                },
            },
            {
                Header: 'Material Code',
                accessor: 'materialCode',
                headerStyle,
                style,
                sortable: false,
               
            },
            {
                Header: 'Division',
                accessor: 'bd1',
                headerStyle,
                style,
                sortable: false,
               
            },
            {
                Header: 'Price',
                accessor: 'price && currency',
                headerStyle,
                style,
                sortable: false,
                Cell: ({ original }) => `${original.currency} ${original.price}`,
            },
            {
                Header: 'Promo',
                accessor: 'productGroupDesc',
                sortable: false,
                headerStyle,
                style,
                Cell: ({ original }) => {
                    return <PromoByProductList id={original.productId} promo="View Promo" />
                },
            },
        ],
        complienceDetailReport: [
            {
                headerStyle,
                columns: [{
                    Header: 'Group Code',
                    accessor: 'branchCode',
                    headerStyle,
                    style,
                    sortable: false,
                    width: 150,
                },
                {
                    Header: 'Group Name',
                    accessor: 'branchName',
                    headerStyle,
                    style,
                    sortable: false,
                    width: 175,
                },
                {
                    Header: 'BM Name',
                    accessor: 'memberNsmName',
                    headerStyle,
                    style,
                    sortable: false,
                    width: 175,
                },
                {
                    Header: 'ASM Name',
                    accessor: 'memberAsmName',
                    headerStyle,
                    style,
                    sortable: false,
                    width: 150,
                },
                {
                    Header: 'NIK Supervisor',
                    accessor: 'memberFssNik',
                    headerStyle,
                    style,
                    sortable: false,
                    width: 150,
                },
                {
                    Header: 'Supervisor Name',
                    accessor: 'memberFssName',
                    headerStyle,
                    style,
                    sortable: false,
                    width: 160,
                },
                {
                    Header: 'NIK Salesman',
                    accessor: 'memberSlmNik',
                    headerStyle,
                    style,
                    sortable: false,
                    width: 150,
                },
                {
                    Header: 'Salesman Name',
                    accessor: 'memberSlmName',
                    headerStyle,
                    style,
                    sortable: false,
                    width: 150,
                },
                {
                    Header: 'Rayon Type',
                    accessor: 'rayonTypeCode',
                    headerStyle,
                    style,
                    sortable: false,
                    width: 150,
                },
                {
                    Header: 'Visit Date',
                    accessor: 'visitDate',
                    headerStyle,
                    style,
                    sortable: false,
                    width: 150,
                    Cell: ({ row }) => formatDate(row.visitDate, 'dd/MM/yyyy')
                },
                {
                    Header: 'Customer Code',
                    accessor: 'outletCode',
                    headerStyle,
                    style,
                    sortable: false,
                    width: 150,
                },
                {
                    Header: 'Customer Name',
                    accessor: 'outletName',
                    headerStyle,
                    style,
                    sortable: false,
                    width: 160,
                },
                {
                    Header: 'Channel',
                    accessor: 'channel',
                    headerStyle,
                    style,
                    sortable: false,
                }]
            },
            {
                Header: 'Master Coordinate',
                headerStyle,
                pivot: true,
                expander: true,
                columns: [{
                    Header: () => {
                        return (
                            <div className="flex flex-row justify-center">
                                <div className="p-1">
                                    <p>LA</p>
                                </div>
                            </div>
                        )
                    },
                    accessor: 'outletLatitude',
                    headerStyle,
                    style,
                    width: 150,
                }, {
                    Header: () => {
                        return (
                            <div className="flex flex-row justify-center">
                                <div className="p-1">
                                    <p>LG</p>
                                </div>
                            </div>
                        )
                    },
                    accessor: 'outletLongitude',
                    headerStyle,
                    style,
                    width: 150,
                }]
            },
            {
                Header: 'Actual Coordinate',
                headerStyle,
                pivot: true,
                expander: true,
                columns: [{
                    Header: () => {
                        return (
                            <div className="flex flex-row justify-center">
                                <div className="p-1">
                                    <p>LA</p>
                                </div>
                            </div>
                        )
                    },
                    accessor: 'actualLatitude',
                    headerStyle,
                    style,
                    width: 150,
                }, {
                    Header: () => {
                        return (
                            <div className="flex flex-row justify-center">
                                <div className="p-1">
                                    <p>LG</p>
                                </div>
                            </div>
                        )
                    },
                    accessor: 'actualLongitude',
                    headerStyle,
                    style,
                    width: 150,
                }]
            },
            {
                headerStyle,
                columns: [
                    {
                        Header: 'Distance Diff Meter',
                        accessor: 'distanceDiffMeter',
                        headerStyle,
                        style,
                        sortable: false,
                        width: 175,
                    },
                    {
                        Header: 'Compliance Category',
                        accessor: 'complienceCategory',
                        headerStyle,
                        style,
                        sortable: false,
                        width: 160,
                    },
                    {
                        Header: 'Location Accuracy',
                        accessor: 'locationAccuracy',
                        headerStyle,
                        style,
                        sortable: false,
                        width: 160,
                    },
                    {
                        Header: 'Distance',
                        // accessor: '',
                        headerStyle,
                        style,
                        sortable: false,
                        Cell: ({ original }) => (<div className="flex justify-center"><PopupDistance data={original} pin={locationIcon} /></div>)
                    }
                ]
            }
        ],
        territoryManagement: [
            {
                Header: 'Group Code',
                accessor: 'territoryCode',
                headerStyle,
                style,
                sortable: false,
                width: 150,
            },
            {
                Header: 'Group Description',
                accessor: 'territoryName',
                headerStyle,
                style,
                sortable: false,
                width: 200,
            },
            {
                Header: 'Rayon Type',
                accessor: 'rayonTypeCode',
                headerStyle,
                style,
                sortable: false,
                width: 175,
            },
            {
                Header: 'Rayon Code',
                accessor: 'rayonCode',
                headerStyle,
                style,
                sortable: false,
                width: 150,
            },
            {
                Header: 'Code User',
                accessor: 'memberNik',
                headerStyle,
                style,
                sortable: false,
                width: 150,
            },
            {
                Header: 'Username',
                accessor: 'memberFullname',
                headerStyle,
                style,
                sortable: false,
                width: 160,
            },
            {
                Header: 'Position',
                accessor: 'memberPosition',
                headerStyle,
                style,
                sortable: false,
                width: 150,
            },
            {
                Header: 'Upperlevel Code',
                accessor: 'upperMemberNik',
                headerStyle,
                style,
                sortable: false,
                width: 150,
            },
            {
                Header: 'Upperlevel Name',
                accessor: 'upperMemberFull',
                headerStyle,
                style,
                sortable: false,
                width: 150,
            },
            {
                Header: 'Active Date',
                accessor: 'activeDate',
                headerStyle,
                style,
                sortable: false,
                width: 150,
                Cell: ({ row }) => formatDate(row.activeDate, 'dd/MM/yyyy')
            },
            {
                Header: 'Action',
                accessor: 'teritoryManagementId',
                headerStyle,
                style,
                sortable: false,
                width: 150,
                Cell: ({ row }) => (
                    <Link className="flex justify-center w-16 py-1 mx-auto text-sm rounded-full" to={{ pathname: `/salesman-activity/territory-management/detail/${row.teritoryManagementId}` }} style={{ backgroundColor: "#117577" }}><img src={editIcon} className="flex justify-center" alt="icon" /></Link>
                )

            },
            {
                Header: 'Last Modified',
                accessor: 'updatedAt',
                headerStyle,
                style,
                sortable: false,
                width: 300,
                Cell: ({ original }) => {
                    return (
                        <span>{formatDate(original.activeDate, 'HH:mm')} - {formatDate(original.activeDate, 'dd/MM/yyyy')} - {original.updatedBy}</span>
                    )
                }
            }
        ],
        callActivitiesbyDate: [
            {
                Header: 'Date',
                accessor: 'date',
                headerStyle,
                style,
                sortable: false,
                width: 150,
            },
            {
                Header: 'ID Customer',
                accessor: 'outlet.outletCode',
                headerStyle,
                style,
                sortable: false,
                width: 200,
            },
            {
                Header: 'Customer Name',
                accessor: 'outlet.outletName',
                headerStyle,
                style,
                sortable: true,
                width: 175,
            },
            {
                Header: 'Channel',
                accessor: 'outlet.initialIndustrialName',
                headerStyle,
                style,
                sortable: true,
                width: 150,
            },
            {
                Header: 'Class',
                accessor: 'outlet.outletClass',
                headerStyle,
                style,
                sortable: true,
                width: 150,
            },
            {
                Header: 'Check-in',
                accessor: 'startVisitDate',
                headerStyle,
                style,
                sortable: true,
                width: 160,
            },
            {
                Header: 'Check-out',
                accessor: 'endVisitDate',
                headerStyle,
                style,
                sortable: true,
                width: 150,
            },
            {
                Header: 'Location Accuracy',
                accessor: 'outlet.locationAccuracy',
                headerStyle,
                style,
                sortable: true,
                width: 150,
            },
            {
                Header: 'Call Deviation',
                accessor: 'routeStatusRaw',
                headerStyle,
                style,
                sortable: true,
                width: 150,
            },
            {
                Header: 'Calls with Orders',
                accessor: 'status',
                headerStyle,
                style,
                sortable: true,
                width: 150,
                Cell: ({ row }) => row.status === 6 ? "Y" : "N"
            },
        ],
        callActivitiesbyOutlet: [
            {
                Header: 'ID Customer',
                accessor: 'outletCode',
                headerStyle,
                style,
                sortable: false,
                width: 200,
            },
            {
                Header: 'Customer Name',
                accessor: 'outletName',
                headerStyle,
                style,
                sortable: true,
                width: 175,
            },
            {
                Header: 'Channel',
                accessor: 'initialIndustrialName',
                headerStyle,
                style,
                sortable: true,
                width: 150,
            },
            {
                Header: 'Class',
                accessor: 'outletClass',
                headerStyle,
                style,
                sortable: true,
                width: 150,
            },
            {
                Header: 'Total Call Plan',
                accessor: 'totalCallPlan',
                headerStyle,
                style,
                sortable: true,
                width: 160,
            },
            {
                Header: 'Total Call Actual',
                accessor: 'totalCallActual',
                headerStyle,
                style,
                sortable: true,
                width: 150,
            },
            {
                Header: '%Call Rate',
                accessor: 'callRate',
                headerStyle,
                style,
                sortable: true,
                width: 150,
            },
            {
                Header: 'Route Call',
                accessor: 'routeCall',
                headerStyle,
                style,
                sortable: true,
                width: 150,
            },
            {
                Header: '%Call Accuracy',
                accessor: 'callAccuracy',
                headerStyle,
                style,
                sortable: true,
                width: 150,
            },
            {
                Header: 'Non Route Call',
                accessor: 'nonRouteCall',
                headerStyle,
                style,
                sortable: true,
                width: 150,
            },
            {
                Header: 'Calls with Orders',
                accessor: 'effectiveCall',
                headerStyle,
                style,
                sortable: true,
                width: 150,
            },
            {
                Header: 'Remaining',
                accessor: 'remaining',
                headerStyle,
                style,
                sortable: true,
                width: 150,
            },
        ],
        srRouteVisit: [
            {
                Header: 'Group',
                accessor: 'branchName',
                headerStyle,
                style,
                sortable: false,
                width: 200,
            },
            {
                Header: 'NIK Salesman',
                accessor: 'memberNik',
                headerStyle,
                style,
                sortable: false,
                width: 150,
            },
            {
                Header: 'Salesman Name',
                accessor: 'memberFullname',
                headerStyle,
                style,
                sortable: false,
                width: 175,
            },
            {
                Header: 'Tanggal',
                accessor: 'visitDate',
                headerStyle,
                style,
                sortable: false,
                width: 150,
                Cell: ({ row }) => formatDate(row.visitDate, 'dd/MM/yyyy')
            },
            {
                Header: 'Customer Location',
                // accessor: '',
                headerStyle,
                style,
                sortable: false,
                Cell: ({ original }) => (<PopupDetailTracking detail={detail} data={original} type='outletLocation' />)
            },
            {
                Header: 'SR Route',
                // accessor: '',
                headerStyle,
                style,
                sortable: false,
                Cell: ({ original }) => (<PopupDetailTracking detail={detail} data={original} type='srRoute' />)
            },
            {
                Header: 'Call Plan',
                accessor: 'callPlan',
                headerStyle,
                style,
                sortable: false,
            },
            {
                Header: 'Call Route',
                accessor: 'callRoute',
                headerStyle,
                style,
                sortable: false,
            },
            {
                Header: 'Call Non Route',
                accessor: 'callNonRoute',
                headerStyle,
                style,
                sortable: false,
            },
        ],
        targetManagementASM: [
            {
                Header: 'No',
                accessor: '',
                headerStyle,
                style,
                sortable: false,
                Cell: ({ index }) => {
                    return (
                        <span>{index + 1}</span>
                    )
                }
            },
            {
                Header: 'NIK - Name',
                accessor: 'nik',
                headerStyle,
                style,
                sortable: false,
                width: 250,
                Cell: ({ original }) => {
                    return (
                        <span>{original.nik} - {original.name}</span>
                    )
                }
            },
            {
                Header: 'Target By Optar',
                accessor: 'target',
                headerStyle,
                style,
                sortable: false,
                width: 175,
            },
            {
                Header: 'Target Forecast by eZEngage',
                accessor: 'targetForecast',
                headerStyle,
                style,
                sortable: false,
                width: 250,
            },
            {
                Header: 'AMS3',
                accessor: 'ams3',
                headerStyle,
                style,
                sortable: false,
                width: 150,
            },
            {
                Header: 'Actual',
                accessor: 'actual',
                headerStyle,
                style,
                sortable: false,
            },
            {
                Header: 'AMS3 vs Target',
                accessor: 'amsVsTarget',
                headerStyle,
                style,
                width: 200,
                sortable: false,
            },
            {
                Header: 'Acv Sales vs Target',
                accessor: 'acvVsTarget',
                headerStyle,
                style,
                width: 200,
                sortable: false,
            },
            {
                Header: 'Gap Actual vs Forecast',
                accessor: 'gapActualVsTarget',
                headerStyle,
                style,
                width: 200,
                sortable: false,
            },
            {
                Header: 'Gap Forecast vs Target',
                accessor: 'gapForecastVsTarget',
                headerStyle,
                style,
                width: 200,
                sortable: false,
            },
            {
                Header: 'Action',
                accessor: '',
                headerStyle,
                style,
                width: 250,
                sortable: false,
                Cell: ({ original }) => {
                    return (
                        <div className="flex">
                            <div className="pr-2">
                                <button className="flex justify-center w-16 py-1 mx-auto text-sm rounded-full" style={{ backgroundColor: "#117577" }} onClick={() => handleEditTarget(original.nik, original.type, original.upperNik, original.UpperType)}><img src={editIcon} className="flex justify-center" alt="icon" /></button>
                            </div>
                            <div className="pr-2">
                                <button className="flex justify-center w-16 py-1 mx-auto text-sm rounded-full button-foriegn"
                                // style={{ backgroundColor: "#ED2E7E" }} 
                                ><img src={deleteIcon} className="flex justify-center" alt="icon" /></button>
                            </div>
                            <div className="pr-2">
                                <p className="font-bold cursor-pointer" style={{ color: "#73984A" }} onClick={() => handleDetailTarget(original.nik, original.type)}>Details</p>
                            </div>
                        </div>
                    )
                }
            },
        ],
        targetManagementDetail: [
            {
                Header: 'No',
                accessor: '',
                headerStyle,
                style,
                sortable: false,
                Cell: ({ index }) => {
                    return (
                        <span>{index + 1}</span>
                    )
                }
            },
            {
                Header: 'NIK',
                accessor: 'memberNik',
                headerStyle,
                style,
                sortable: false,
                width: 150,
            },
            {
                Header: 'Salesman',
                accessor: 'memberFullname',
                headerStyle,
                style,
                sortable: false,
                width: 150,
            },
            {
                Header: 'Customer Code',
                accessor: 'outletCode',
                headerStyle,
                style,
                sortable: false,
                width: 150,
            },
            {
                Header: 'Achievement Group',
                accessor: 'principalDivisionName',
                headerStyle,
                style,
                sortable: false,
                width: 150,
            },
            {
                Header: 'Division',
                accessor: 'principalDivisionCode',
                headerStyle,
                style,
                sortable: false,
            },
            {
                Header: 'Target by OPTAR',
                accessor: 'targetOptar',
                headerStyle,
                style,
                width: 200,
                sortable: false,
            },
            {
                Header: 'Target Forecast by eZEngage',
                accessor: 'targetForecast',
                headerStyle,
                style,
                width: 200,
                sortable: false,
            },
            {
                Header: 'AMS3',
                accessor: 'ams3',
                headerStyle,
                style,
                width: 200,
                sortable: false,
            },
            {
                Header: 'Actual',
                accessor: 'actual',
                headerStyle,
                style,
                width: 200,
                sortable: false,
            },
            {
                Header: 'AMS3 vs Target',
                accessor: 'ams3VsTarget',
                headerStyle,
                style,
                width: 200,
                sortable: false,
            },
            {
                Header: 'Acv Sales vs Target',
                accessor: 'acvSalesVsTarget',
                headerStyle,
                style,
                width: 200,
                sortable: false,
            },
            {
                Header: 'Gap actual vs Forecast',
                accessor: 'gapActualVsForecast',
                headerStyle,
                style,
                width: 200,
                sortable: false,
            },
            {
                Header: 'Gap Forecast vs target',
                accessor: 'gapForecastVsTarget',
                headerStyle,
                style,
                width: 200,
                sortable: false,
            },
        ],
        targetManagementEdit: [
            {
                Header: 'No',
                accessor: '',
                headerStyle,
                style,
                sortable: false,
                Cell: ({ index }) => {
                    return (
                        <span>{index + 1}</span>
                    )
                }
            },
            {
                Header: 'Rayon Code',
                accessor: 'rayonCode',
                headerStyle,
                style,
                sortable: false,
                width: 150,
            },
            {
                Header: 'Salesman',
                accessor: 'memberFullname', //gaada
                headerStyle,
                style,
                sortable: false,
                width: 150,
            },
            {
                Header: 'Achievement Group',
                accessor: 'principalDivisionName',
                headerStyle,
                style,
                sortable: false,
                width: 150,
            },
            {
                Header: 'Division',
                accessor: 'principalDivisionCode',
                headerStyle,
                style,
                sortable: false,
            },
            {
                Header: 'Sales Target by OPTAR',
                accessor: 'targetOptar',
                headerStyle,
                style,
                width: 200,
                sortable: false,
            },
            {
                Header: 'Target Forecast by eZEngage',
                accessor: 'targetForecast',
                headerStyle,
                style,
                width: 200,
                sortable: false,
            },
            {
                Header: 'AMS3',
                accessor: 'ams3',
                headerStyle,
                style,
                width: 200,
                sortable: false,
            },
            {
                Header: 'AMS3 vs Target',
                accessor: 'ams3VsTarget',
                headerStyle,
                style,
                width: 200,
                sortable: false,
            },
            {
                Header: 'Gap Forecast vs target',
                accessor: 'gapForecastVsTarget',
                headerStyle,
                style,
                width: 200,
                sortable: false,
            },
            {
                Header: 'Contribution Sales Target',
                accessor: 'contributionSalesTarget',
                headerStyle,
                style,
                width: 200,
                sortable: false,
            },
            {
                Header: 'Contribution Forecast Target',
                accessor: 'contributionForecastTarget',
                headerStyle,
                style,
                width: 200,
                sortable: false,
            },
            {
                Header: 'Contribution AMS3',
                accessor: 'contributionAms3',
                headerStyle,
                style,
                width: 200,
                sortable: false,
            },
            {
                Header: 'Action',
                accessor: '',
                headerStyle,
                style,
                width: 250,
                sortable: false,
                Cell: ({ original }) => {
                    return (
                        <div className="flex">
                            <div className="pr-2">
                                {/* <button className="flex justify-center w-16 py-1 mx-auto text-sm rounded-full" style={{ backgroundColor: "#117577" }} ><img src={editIcon} className="flex justify-center" alt="icon" /></button> */}
                                <SelectOption
                                    name='memberSlmNik'
                                    value='Action'
                                >
                                    <MenuItem value="Action">Action</MenuItem>
                                    <MenuItem value="Approved">Approved</MenuItem>
                                    <MenuItem value="Disapproved">Disapproved</MenuItem>


                                </SelectOption>
                            </div>
                            <div className="pr-2">
                                <button className="flex justify-center w-16 py-1 mx-auto text-sm rounded-full button-foriegn"
                                // style={{ backgroundColor: "#ED2E7E" }}
                                ><img src={deleteIcon} className="flex justify-center" alt="icon" /></button>
                            </div>
                            <div className="pr-2">
                                <p className="font-bold cursor-pointer" style={{ color: "#73984A" }} onClick={() => handleEditDetailTarget(original.rayonCode, original.upperNik, original.upperType)}>Details</p>
                            </div>
                        </div>
                    )
                }
            },
        ],
        targetManagementEditDetail: [
            {
                Header: 'No',
                accessor: '',
                headerStyle,
                style,
                sortable: false,
                Cell: ({ index }) => {
                    return (
                        <span>{index + 1}</span>
                    )
                }
            },
            {
                Header: 'Rayon Code',
                accessor: 'rayonCode',
                headerStyle,
                style,
                sortable: false,
                width: 150,
            },
            {
                Header: 'Salesman',
                accessor: 'memberFullname',
                headerStyle,
                style,
                sortable: false,
                width: 150,
            },
            {
                Header: 'Customer Code',
                accessor: 'outletCode',
                headerStyle,
                style,
                sortable: false,
                width: 150,
            },
            {
                Header: 'Customer Name',
                accessor: 'outletName',
                headerStyle,
                style,
                sortable: false,
                width: 150,
            },
            {
                Header: 'Achievement Group',
                accessor: 'principalDivisionName',
                headerStyle,
                style,
                sortable: false,
                width: 150,
            },
            {
                Header: 'Division',
                accessor: 'principalDivisionCode',
                headerStyle,
                style,
                sortable: false,
            },
            {
                Header: 'Sales Target by OPTAR',
                accessor: 'targetOptar',
                headerStyle,
                style,
                width: 200,
                sortable: false,
            },
            {
                Header: 'Target Forecast by eZEngage',
                accessor: 'targetForecast',
                headerStyle,
                style,
                width: 200,
                sortable: false,
            },
            {
                Header: 'AMS3',
                accessor: 'ams3',
                headerStyle,
                style,
                width: 200,
                sortable: false,
            },
            {
                Header: 'AMS3 vs Target',
                accessor: 'ams3VsTarget',
                headerStyle,
                style,
                width: 200,
                sortable: false,
            },
            {
                Header: 'GAP Forecast vs target',
                accessor: 'gapForecastVsTarget',
                headerStyle,
                style,
                width: 200,
                sortable: false,
            },
            {
                Header: 'Contribution Sales Target',
                accessor: 'contributionSalesTarget',
                headerStyle,
                style,
                width: 200,
                sortable: false,
            },
            {
                Header: 'Contribution Forecart Target',
                accessor: 'contributionForecastTarget',
                headerStyle,
                style,
                width: 200,
                sortable: false,
            },
            {
                Header: 'Contribution AMS3',
                accessor: 'contributionAms3',
                headerStyle,
                style,
                width: 200,
                sortable: false,
            },
        ],
        collectionCollectedInvList: [
            {
                Header: () => {
                    return (
                        <input
                            type='checkbox'
                            checked={selectedData.all === 1}
                            indeterminate={selectedData.all === 2 ? "true" : "false"}
                            onChange={() => handleSelectedAllRows()}
                        />
                    )
                },
                accessor: '',
                width: 50,
                sortable: false,
                headerStyle,
                className: '', // belum di isi
                Cell: ({ original }) => {
                    return (
                        <input
                            type='checkbox'
                            checked={selectedData.item[original[checkboxProps.selectorKey]] === true}
                            onChange={() => handleSelectRow(original[checkboxProps.selectorKey])}
                        />
                    )
                }
            },
            {
                Header: 'Invoice Number',
                accessor: 'invoiceNumber',
                headerStyle,
                className: '',
                sortable: true,
            },
            {
                Header: 'customer Code',
                accessor: 'customerCode',
                headerStyle,
                className: '',
                sortable: true,
            },
            {
                Header: 'customer Name',
                accessor: 'customerName',
                headerStyle,
                className: '',
                sortable: true,
            },
            {
                Header: 'Payment Method',
                accessor: 'paymentMethod',
                headerStyle,
                className: '',
                sortable: true,
            },
            {
                Header: 'Payment Type',
                accessor: 'paymentType',
                headerStyle,
                className: '',
                sortable: true,
            },
            {
                Header: 'Bank Name',
                accessor: 'bankName',
                headerStyle,
                className: '',
                sortable: true,
            },
            {
                Header: 'Due Date',
                accessor: 'dueDate',
                headerStyle,
                className: '',
                sortable: true,
            },
            {
                Header: 'Invoice Value',
                accessor: 'invoiceValue',
                headerStyle,
                className: '',
                sortable: true,
            },
            {
                Header: 'Invoice Balance',
                accessor: 'invoiceBalance',
                headerStyle,
                className: '',
                sortable: true,
            },
            {
                Header: 'Payment Value',
                accessor: 'paymentValue',
                headerStyle,
                className: '',
                sortable: true,
            },
            {
                Header: 'Titipan Pelanggan',
                accessor: 'titipanPelanggan',
                headerStyle,
                className: '',
                sortable: true,
            },
            {
                Header: 'Duplicate',
                accessor: '',
                headerStyle,
                className: '',
                sortable: true,
            }
        ],
        arCollected: [
            {
                Header: () => {
                    return (
                        <input
                            type='checkbox'
                            checked={selectedData.all === 1}
                            indeterminate={selectedData.all === 2 ? "true" : "false"}
                            onChange={() => handleSelectedAllRows()}
                        />
                    )
                },
                accessor: '',
                width: 50,
                sortable: false,
                headerStyle,
                className: '', // belum di isi
                Cell: ({ original }) => {
                    return (
                        <input
                            type='checkbox'
                            checked={selectedData.item[original[checkboxProps.selectorKey]] === true}
                            onChange={() => handleSelectRow(original[checkboxProps.selectorKey])}
                        />
                    )
                }
            },
            {
                Header: 'DIH No',
                accessor: 'dihNo',
                headerStyle,
                className: '',
                sortable: true,
            },
            {
                Header: 'Total Invoice',
                accessor: 'totalInvoice',
                headerStyle,
                className: '',
                sortable: true,
            },
        ],
        historyCollected: [
            {
                Header: 'Tanggal',
                accessor: 'billingDate',
                headerStyle,
                className: '',
                sortable: true,
                Cell: ({ row }) => formatDate(row.billingDate, 'dd-MM-yyyy')
            },
            {
                Header: 'Description',
                accessor: 'batchRaw',
                headerStyle,
                className: '',
                sortable: true,
            },
            {
                Header: 'Total E-Deposit',
                accessor: 'totalDeposit',
                headerStyle,
                className: '',
                sortable: true,
            },
            {
                Header: 'View E-Deposit',
                accessor: '',
                headerStyle,
                className: '',
                sortable: false,
                Cell: ({ row }) => {
                    return <Link style={{ color: '#117577' }} to={{ pathname: `/collection-collected/history-list/${row._original.arInvoiceId}` }}>
                        View E-Deposit
                    </Link>
                }
            }
        ],
        historyList: [
            {
                Header: 'Payment Method',
                accessor: 'paymentMethodName',
                headerStyle,
                className: '',
                sortable: true,
            },
            {
                Header: 'Transfer Number',
                accessor: 'transferNo',
                headerStyle,
                className: '',
                sortable: true,
            },
            {
                Header: 'Customer Code',
                accessor: 'customerCode',
                headerStyle,
                className: '',
                sortable: true,
            },
            {
                Header: 'Group Code',
                accessor: 'groupCode',
                headerStyle,
                className: '',
                sortable: true,
            },
            {
                Header: 'Bank Transfer Name',
                accessor: 'bankName',
                headerStyle,
                className: '',
                sortable: true,
            },
            {
                Header: 'Total Bayar',
                accessor: 'totalPayment',
                headerStyle,
                className: '',
                sortable: true,
                Cell: ({ original }) => `Rp ${toCurrency(original.totalPayment)}`
            },
            {
                Header: 'Total Cost',
                accessor: 'totalCost',
                headerStyle,
                className: '',
                sortable: true,
                Cell: ({ original }) => `Rp ${toCurrency(original.totalCost)}`
            },
            {
                Header: 'Total Titipan',
                accessor: 'totalTitipan',
                headerStyle,
                className: '',
                sortable: true,
                Cell: ({ original }) => `Rp ${toCurrency(original.totalTitipan)}`
            },
            {
                Header: 'Total Net',
                accessor: 'totalNet',
                headerStyle,
                className: '',
                sortable: true,
                Cell: ({ original }) => `Rp ${toCurrency(original.totalNet)}`
            },
        ],
        promoReportDetail: [
            {
                Header: 'Group',
                accessor: 'branchCode',
                headerStyle,
                style,
                sortable: false,
            },
            {
                Header: 'BM Name',
                accessor: 'memberNsmFullName',
                headerStyle,
                style,
                sortable: false,
                width: 200,
            },
            {
                Header: 'ASM Name',
                accessor: 'memberAsmFullName',
                headerStyle,
                style,
                sortable: false,
                width: 200,
            },
            {
                Header: 'NIK Supervisor',
                accessor: 'memberFssNik',
                headerStyle,
                style,
                sortable: false,
                width: 150,
            },
            {
                Header: 'NIK Salesman',
                accessor: 'memberSlmNik',
                headerStyle,
                style,
                sortable: false,
                width: 150,
            },
            {
                Header: 'Salesman Name',
                accessor: 'memberSlmFullName',
                headerStyle,
                style,
                sortable: false,
                width: 150,
            },
            {
                Header: 'Rayon Type',
                accessor: 'rayonCode',
                headerStyle,
                style,
                sortable: false,
                width: 150,
            },
            {
                Header: 'Date',
                accessor: 'date',
                headerStyle,
                style,
                sortable: false,
                width: 200,
                Cell: ({ row }) => formatDate(row.date, 'dd-MM-yyyy')
            },
            {
                Header: 'Customer Code',
                accessor: 'outletCode',
                headerStyle,
                style,
                sortable: false,
                width: 150,
            },
            {
                Header: 'Customer Name',
                accessor: 'outletName',
                headerStyle,
                style,
                sortable: false,
                width: 100,
            },
            {
                Header: 'Channel',
                accessor: 'channelCode',
                headerStyle,
                style,
                sortable: false,
                width: 100,
            },
            {
                Header: 'Product Code',
                accessor: 'materialCodeM',
                headerStyle,
                style,
                sortable: false,
                width: 100,
            },
            {
                Header: 'Product Name',
                accessor: 'productName',
                headerStyle,
                style,
                sortable: false,
                width: 100,
            },
            {
                Header: 'Promo Code',
                accessor: 'promoId',
                headerStyle,
                style,
                sortable: false,
                width: 200,
            },
            {
                Header: 'Start Time',
                accessor: 'startTime',
                headerStyle,
                style,
                sortable: false,
                width: 200,
                Cell: ({ row }) => formatDate(row.startTime, 'dd-MM-yyyy H:i:s')
            },
            {
                Header: 'Finish Time',
                accessor: 'endTime',
                headerStyle,
                style,
                sortable: false,
                width: 200,
                Cell: ({ row }) => formatDate(row.endTime, 'dd-MM-yyyy H:i:s')
            },
            {
                Header: 'Duration',
                accessor: 'duration',
                headerStyle,
                style,
                sortable: false,
                width: 150,
            },
            {
                Header: 'Principal',
                accessor: 'principal',
                headerStyle,
                style,
                sortable: false,
                width: 100,
            },
            {
                Header: 'Division',
                accessor: 'divisionCode',
                headerStyle,
                style,
                sortable: false,
                width: 100,
            },
        ],
    }
}


export default useFieldRowThree;