import React from 'react'
import ReactTable from 'react-table-v6'
import 'react-table-v6/react-table.css'

const useTableBody = (props) => {
  const {
    data,
    type,
    pages,
    // manual,
    // pageSize,
    onFetchData,
    // dataListLength,
    selectedData,
    checkboxProps,
    showPagination,
    handleSelectedData,
    setcheckboxdata,
    checkboxdata
    // handleChangeQty,
    // handleChangeJkn
  } = props
  const renderTable = (dataTable, fieldsColumns) => {
    const tableColumns = []
    fieldsColumns[type].map((column, index) => (
      tableColumns.push(column)
    ))



    return (
      <ReactTable
        pages={pages}
        // manual={manual}
        data={dataTable}
        resizable={true}
        columns={tableColumns}
        onFetchData={onFetchData}
        showPagination={showPagination}
        className='mb-3 -striped -highlight'
        style={{
          height: props.withModal && '500px'
        }}
        pageSize={(dataTable.length === 0) ? 5 : dataTable.length}
        NoDataComponent={() => <div className="rt-noData" style={{ background: 'unset' }}>No Data</div>}
      />
    )
  }

  // const handleSelectedAllRows = () => {
  //   debugger;
  //   const selectedTemp = {};
  //   if (selectedData.all === 0) {
  //     data.map(item => {
  //       return selectedTemp[item[checkboxProps.selectorKey]] = true
  //     })
  //   }

  //   handleSelectedData({
  //     all: selectedData.all === 0 ? 1 : 0,
  //     item: selectedTemp,
  //   })
  // }

  const handleSelectedAllRows = () => {
    const selectedTemp = {};
    let allValue = 1; // Assume all rows will be selected

    if (selectedData.all === 1) {
      allValue = 0; // If all rows are already selected, deselect them
    }

    data.forEach(item => {
      selectedTemp[item[checkboxProps.selectorKey]] = allValue === 1; // Set all rows to selected or deselected
      selectedTemp[item[checkboxProps.selectorKey1]] = allValue === 1; // Also set the associated selectorKey1
    });

    handleSelectedData({
      all: allValue,
      item: selectedTemp,
    });
  };


  // const handleSelectRow = (key, MemberPositionId, data, index = '') => {
  //   debugger;
  //   setcheckboxdata([...checkboxdata, data] ? data : [])
  //   const selectedTemp = { ...selectedData.item }
  //   selectedTemp[key] = !selectedData.item[key]
  //   selectedTemp[MemberPositionId] = !selectedData.item[MemberPositionId]

  //   let counterTrue = 0
  //   Object.keys(selectedTemp).map(item => {
  //     if (selectedTemp[item]) return counterTrue += 1
  //     return false
  //   })

  //   let allValue = 0;
  //   if (counterTrue > 0) {
  //     allValue = 1
  //   } else {
  //     allValue = 2
  //   }

  //   handleSelectedData({
  //     all: allValue,
  //     item: selectedTemp,
  //     row: {
  //       index: index,
  //       status: selectedTemp[key]
  //     },
  //   })

  // }

  const handleSelectRow = (key, MemberPositionId, data, index = '') => {
    const selectedTemp = { ...selectedData.item };

    // Toggle the selected state of the clicked row
    selectedTemp[key] = !selectedData.item[key];
    selectedTemp[MemberPositionId] = !selectedData.item[MemberPositionId];

    let allSelected = true;
    let someSelected = false;

    // Check if all or some rows are selected
    for (const itemKey in selectedTemp) {
      if (!selectedTemp[itemKey]) {
        allSelected = false;
      } else {
        someSelected = true;
      }
    }

    let allValue = allSelected ? 1 : (someSelected ? 2 : 0); // Indeterminate state if some rows are selected but not all

    handleSelectedData({
      all: allValue,
      item: selectedTemp,
      row: {
        index: index,
        status: selectedTemp[key]
      },
    });
  };

  return {
    renderTable,
    handleSelectedAllRows,
    handleSelectRow
  }
}

export default useTableBody
