import React from 'react';
import { Navigate } from "react-router-dom";
import useToken from "../../utils/useToken";

export function RequireAuth({ element: Component, ...rest }) {
    let storeTokenData = useToken();

    if (!storeTokenData?.token()) {
        return <Navigate to="/redirect" state={{ from: rest.location }} />;
    }

    // console.log('Component type:', typeof Component);
    // console.log('Is valid React element:', React.isValidElement(Component));
    // console.log('Component prototype:', Component.prototype);

    if (typeof Component === 'function' || typeof Component === 'object' || (Component.prototype && Component.prototype.isReactComponent)) {
        // Component is a function or class component
        return <Component {...rest} />;
    } else if (React.isValidElement(Component)) {
        // Component is a React element
        return Component;
    } else {
        // Handle other cases if necessary
        return null;
    }
    // return <Component {...rest} />;
};