import React, { forwardRef } from 'react'
import { makeStyles } from '@mui/styles'

const useStyle = makeStyles((theme) => ({
    input: {
        background: '#EFF0F6',
        width: '100%',
        border: 'none',
        outline: 'none',
        boxShadow: 'none',
        padding: '8px',
        borderRadius: '9999px'
    }
}))

const BaseInput = forwardRef((props, ref) => {
    const classes = useStyle()

    return (
        <input
            className={classes.input}
            autoComplete="off"
            {...props}
        />
    )
})

export default React.memo(BaseInput)
